import styled from "styled-components";

// React Router Dom
import { Link } from "react-router-dom";

export const BgContainerImage = styled.div`
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${(props) => props.background});
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;

export const Colors = {
  primary: "#fff",
  theme: "#BE185D",
  light1: "#F3F4F6",
  light2: "#E5E7EB",
  dark1: "#1F2937",
  dark2: "#4B5563",
  dark3: "9CA3AF",
  red: "#DC2626",
};

export const BgContainer = styled.div`
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const FormPageCountainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

export const FormContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 100px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const LogoContainer = styled.div`
  margin: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
`;

export const LogoTag = styled.img`
  height: auto;
  width: 250px;
`;

export const TextLabelContainer = styled.div`
  margin: 10px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  .label {
    color: white;
    font-weight: bold;
    padding: 1px;
    margin-bottom: 5px;
  }

  .input {
    height: 30px;
    padding-left: 50px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
    box-shadow: 2px 2px 5px 3px rgba(46, 46, 46, 0.2);
    border: solid;
    border-width: 0.5px 5px 0.5px 5px;
    border-color: black #427ef5 black #427ef5;
    dsiplay: block;
    margin: 5px auto 10px auto;
    transition: ease-in-out 0.2s;
  }

  .select {
    height: 30px;
    width: 100%;
    padding-left: 50px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: bold;
    box-shadow: 2px 2px 5px 3px rgba(46, 46, 46, 0.2);
    border: solid;
    border-width: 0.5px 5px 0.5px 5px;
    border-color: black #427ef5 black #427ef5;
    dsiplay: block;
    margin: 5px auto 10px auto;
    transition: ease-in-out 0.2s;
  }

  .eye {
    font-size: 20px;
    position: absolute;
    top: 43.5%;
    color: black;
  }

  .errMsg {
    font-size: 14px;
    color: white;
    padding-left: 8px;
    margin-top: -5px;
    margin-bottom: 2px;
    text-align: left;
    background-color: red;
  }

  @media (min-width: 250px) {
    .input {
      width: full;
    }

    .eye {
      right: 8%;
    }
  }

  @media (min-width: 361px) {
    .input {
      width: 250px;
    }
  }

  @media (min-width: 451px) {
    .input {
      width: 320px;
    }
    .eye {
      right: 6%;
    }
  }

  @media (min-width: 650px) {
    .input {
      width: 360px;
    }
  }

  @media (min-width: 850px) {
    .input {
      width: 450px;
    }
    .eye {
      right: 4%;
    }
  }

  @media (min-width: 1100px) {
    .input {
      width: 540px;
    }
    .eye {
      right: 3%;
    }
  }

  @media (min-width: 1200px) {
    .input {
      width: 600px;
    }
  }
`;

export const FormBtnContainer = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormBtn = styled.button`
  text-align: center;
  background-color: #0065a3;
  color: white;
  border-radius: 25px;
  border-width: 1px;
  font-size: 15px;
  font-weight: bold;
  width: 200px;
  height: 30px;
  transition-duration: 0.5s;
  box-shadow: 2px 2px 5px 3px rgba(46, 46, 46, 0.2);
  cursor: pointer;
  &: hover {
    background-color: transparent;
    color: black;
  }
`;

export const Nav = styled.nav`
  z-index: 10;
  background-color: ${(props) =>
    props.bckColor ? props.bckColor : "rgb(0, 101, 163)"};
  color: white;
  position: sticky;
  top: 0;
  width: 100%;
`;

export const NavBottom = styled.nav`
  z-index: 10;
  background-color: #1108df;
  color: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 115px;
`;

export const NavbarContainer = styled.nav`
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavbarContainerBottom = styled.nav`
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SidenavBtnContainer = styled.div`
  padding: 8px;
  margin: 8px;
`;

export const UserListBtnContainer = styled.div`
  padding: 8px;
  margin: 8px;
`;

export const IconBtn = styled.button`
  font-size: ${(props) => props.size}px;
  cursor: pointer;
  border: none;
  color: white;
  background-color: ${(props) =>
    props.bckColor ? props.bckColor : "rgb(0, 101, 163)"};
  &: hover {
    color: ${(props) => props.hoverColor};
  }
`;

export const UserIconList = styled.div`
  width: 130px;
  background-color: white;
  position: absolute;
  top: 65px;
  right: 20px;
  border-radius: 15px;
`;

export const List = styled.ul`
  text-align: center;
  list-style-type: none;
  padding: 0;
`;

export const ItemsList = styled.li`
  padding: 5px;
  margin: 2px;
`;

export const ItemsLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-weight: bold;
`;

// export const LogouBtn = styled.button`
//   color: black;
//   font-weight: bold;
//   text-decoration: none;
//   border: none;
//   font-size: 15px;
//   cursor: pointer;
//   background-color: white;
// `;

export const SidebarConatiner = styled.div`
  height: 100vh;
  width: 250px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.bckColor ? props.bckColor : "rgb(0, 101, 163)"};
  transition: 1s;
  overflow-x: hidden;
  color: white;
`;

export const SidebarHeader = styled.div`
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SbHNameContainer = styled.div`
  padding: 8px;
  margin: 8px;
`;

export const SbHName = styled.h1`
  font-size: 20px;
  font-weight: bold;
  color: white;
`;

export const SidebarDivider = styled.hr`
  background-color: gray;
  border: none;
  height: 6px;
  margin: 0;
`;

export const SidebarBody = styled.div`
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SbBElementContainer = styled.div`
  width: 100%;
  height: 50px;
  padding-top: 5px;
  padding-left: 50px;
  padding-bottom: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  display: block;
  &:hover {
    background-color: rgba(160, 160, 160, 0.5);
    cursor: pointer;
  }
`;

export const SbBElement = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: black;
  &:hover {
    color: white;
  }
`;

export const LogoBox = styled.div`
  width: 4rem;
  padding: 0.125rem;
  margin: 0.125rem;
  @media (min-width: 768px) {
    width: 5.3rem;
  }
`;

export const SmallLogosContainer = styled.div`
  padding: 0.125rem;
  margin: 0.125rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  @media (max-height: 530px) {
    position: static;
  }
`;

export const DashboardPageCountainer = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DashboardHeaderContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 15px 10px 15px 10px;
  border-radius: 0 0 15px 15px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 20px rgba(0, 0, 0, 0.2);
  background-color: rgba(230, 230, 230, 0.35);

  @media (max-width: 535px) {
    flex-direction: column;
  }
`;

export const DHNameContainer = styled.div`
  padding: 8px;
  margin: 8px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DHBarContainer = styled.div`
  padding: 8px;
  margin: 8px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 922px) {
    flex-direction: column;
  }
`;

export const DHBSubContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 280px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const DashboardBodyContainer = styled.div`
  height: auto;
  display: flex;
  padding: 8px;
  margin: 15px 10px 15px 10px;
  border-radius: 15px 15px 0 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 20px rgba(0, 0, 0, 0.2);
  background-color: rgba(230, 230, 230, 0.35);
`;

export const TableStyles = styled.div`
  padding: 1px;
  overflow: auto;
  table {
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 1rem;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TableContainer = styled.div`
  margin: 2px;
  padding: 2px;
  width: 99%;
  height: auto;
  border: 2px solid black;
  border-radius: 15px 15px 0 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  margin: 2px;
  padding: 2px;
  width: auto;
  height: auto;
  border: 2px solid black;
  border-radius: 15px 15px 0 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: ${(props) => (props.align ? props.align : null)};

  .dateContainer {
    width: auto;
    padding: 3px 5px;
    display: flex;
  }
  .pdfExcelBtnContainer {
    width: auto;
    padding: 3px 5px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 639px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dateContainer input {
      width: 25%;
    }
  }
  @media (min-width: 640px) and (max-width: 893px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dateContainer input {
      width: 50%;
    }
  }
`;

export const ContainerButton = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustButton = styled.button`
  color: white;
  margin: 5px;
  padding: 2px;
  background-color: ${(props) => (props.color ? props.color : "red")};
  font-size: 15px;
  width: 150px;
  height: 30px;
  border: 2px solid black;
  border-radius: 25px;
  transition: ease-in-out 0.3s;
  font-weight: bold;
  text-align: center;
  box-shadow: 2px 2px 5px 3px rgba(46, 46, 46, 0.2);
  &:hover {
    background-color: transparent;
    cursor: pointer;
    color: black;
  }
  @media (max-width: 639px) {
    width: 120px;
    font-size: 12px;
  }
  @media (min-width: 640px) {
    width: 160px;
    font-size: 15px;
  }
`;

export const TitleH2 = styled.h2`
  font-size: ${(props) => props.size}px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : Colors.primary)};
  padding: 5px;
  margin-bottom: 20px;
`;

export const SubTitleP = styled.p`
  font-size: ${(props) => props.size}px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : Colors.primary)};
  padding: 5px;
  margin: 20px;
  margin-bottom: 20px;
`;

// export const DivButton = styled.div`
//   padding: 5px;
//   margin-top: 50px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export const LinkButton = styled(Link)`
  color: white;
  padding: 2px;
  background-color: ${(props) => (props.color ? props.color : "red")};
  font-size: 18px;
  width: 250px;
  height: 35px;
  border: 2px solid black;
  border-radius: 25px;
  transition: ease-in-out 0.3s;
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: transparent;
    cursor: pointer;
  }
`;

export const DivLink = styled.div`
  padding: 3px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ComProfLink = styled.a`
  color: white;
  font-size: 20px;
  text-align: center;
  display: table-cell;
  transition: ease-in-out 0.3s;
  &:hover {
    color: black;
    cursor: pointer;
  }
`;

// Copyright
export const CopyrightText = styled.p`
  padding: 0.125rem;
  margin: 0.125rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  color: white;

  // position: relative;
  // padding: 5px;
  // margin: 20px;
  // text-align: center;
  // color: white;
  // top: 100px;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LightContainer = styled.div`
  // margin: 2px;
  // padding: 2px;
  width: 100%;
  height: auto;
  border-top: 2px solid black;
  border-radius: 0;
  // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: ${(props) => (props.align ? props.align : null)};
  align-items: center;

  .lightControlContainer {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .lightBtnContainer {
    width: auto;
    padding: 3px 5px;
    display: flex;
  }

  @media (max-width: 767.5px) {
    .lightControlContainer {
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
    }
  }
`;
